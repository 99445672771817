import classNames from 'classnames'
import {
  type ButtonHTMLAttributes,
  type FC,
  type MouseEvent,
  type ReactNode,
  useState,
} from 'react'

import Icon, { type IconName } from '../Icon/Icon'
import Loader from '../Loader/Loader'
import styles from './button.module.scss'

export type ButtonSize = 's' | 'm' | 'l' // button size. 's' - 40px, 'm' - 50px, 'l' - 60px

/*
 Button component
 It accepts all properties for html button
 and some properties for customisation:
 label, size, wide, loading, variant, icon
 and also children
 */
export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string // text on button
  labelClassName?: string
  size?: ButtonSize
  contentWidth?: boolean // button width is content width
  loading?: boolean // if true show loader on button, content hidden
  loadingAfterClick?: boolean // if true show loader on button after click, content hidden
  variant?: 'filled' | 'outlined' | 'text' // view variant
  icon?: IconName // icon element for button
  customIcon?: ReactNode // custom icon element for button
  iconSize?: number // default is 12px
  iconPosition?: 'left' | 'right' // icon position
  iconWrapperClassName?: string
  pulsed?: boolean // pulsed animation
  className?: string // className for customisation
  withScaling?: boolean // if true button will be scaled on wide screen
}

const Button: FC<Props> = ({
  onClick,
  label,
  labelClassName = '',
  size = 'm',
  contentWidth,
  variant = 'filled',
  children,
  type = 'button',
  icon,
  iconSize,
  iconPosition = 'left',
  iconWrapperClassName = '',
  loading,
  loadingAfterClick,
  pulsed,
  className = '',
  withScaling,
  customIcon,
  ...props
}) => {
  const [innerLoading, setInnerLoading] = useState(false)

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (loadingAfterClick) {
      setInnerLoading(true)
    }

    if (onClick) {
      onClick(e)
    }
  }

  const isLoading = loading || innerLoading
  const buttonContent = children || label

  const buttonClassNames = classNames(
    styles.button,
    styles[`size_${size}`],
    styles[variant],
    {
      [styles[`size_scaled_${size}`]]: withScaling,
      [styles.pulsed]: pulsed,
      [styles.content_width]: contentWidth,
      [className]: Boolean(className),
    }
  )

  const contentWrapperClassName = classNames(styles.content_wrapper, {
    [styles.content_loading]: isLoading,
  })

  const contentClassName = classNames(labelClassName, {
    [styles[`content_with_icon_${iconPosition}`]]:
      Boolean(icon) || Boolean(customIcon),
  })

  const iconWrapperClassNames = classNames(styles.icon_wrapper, {
    [iconWrapperClassName]: Boolean(iconWrapperClassName),
  })

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={buttonClassNames}
      {...props}
      onClick={handleButtonClick}
    >
      {isLoading && (
        <Loader size={28} color={variant === 'filled' ? '#fff' : '#000'} />
      )}
      <div className={contentWrapperClassName}>
        {icon && (
          <div className={iconWrapperClassNames}>
            <Icon
              icon={icon}
              className={styles.icon}
              size={iconSize}
              scaled={withScaling}
            />
          </div>
        )}
        {customIcon && !icon && (
          <div className={styles.icon_wrapper}>{customIcon}</div>
        )}
        {buttonContent && (
          <span className={contentClassName}>{buttonContent}</span>
        )}
      </div>
    </button>
  )
}

export default Button
